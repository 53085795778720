import { Injectable } from '@angular/core'
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth/auth.service'
import { UserService } from '../services/user/user.service'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.isLogged().then(() => {
            const role = this.authService.getRoleValue()
            //////////console.log("AuthGuard role: " + role);
            if (this.authService.isLoggedIn.getValue()) {
                if (!role) {
                    //////////console.log("Getting Info...");
                    return this.handleInfoUserRequest(state)
                }
                return this.checkLanding(state)
            }
            return this.redirectToLanding(state)
        })
    }

    private handleInfoUserRequest(
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        //////////console.log("Getting Info...");
        return this.authService.doRefreshToken().then(
            () => {
                return this.checkLanding(state)
            },
            (err) => {
                this.authService.logout()
                return this.redirectToLanding(state)
            },
        )
    }

    private redirectToLanding(state: RouterStateSnapshot): boolean {
        if (state.url === '/landing') {
            return true
        }
        this.router.navigate(['/landing'])
        return false
    }

    private checkLanding(state: RouterStateSnapshot): boolean {
        //////////console.log("Got Info..., GOING UP, SUBINDO");
        if (state.url === '/landing') {
            this.router.navigate(['/asignaciones'])
            return false
        }
        return true
    }
}
