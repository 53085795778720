import {
    BehaviorSubject,
    Observable,
    Subject,
    firstValueFrom,
    map,
    timeout,
} from 'rxjs'
import { AlertService } from '../alert/alert.service'
import {
    HttpClient,
    HttpHeaders,
    HttpErrorResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EnvService } from '../env/env.service'
import { TokenService } from '../token/token.service'
import { SelfUser } from 'src/app/models/models'

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    public user: SelfUser = {
        uuid: '',
        name: '',
        email: '',
        role: '',
    }
    public userSubscription: BehaviorSubject<SelfUser> = new BehaviorSubject(
        {} as SelfUser,
    )
    public isUserReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    constructor(
        private http: HttpClient,
        private env: EnvService,
        private tokenService: TokenService,
    ) {
        this.token = this.tokenService.getToken()

        this.token.subscribe((token) => {
            if (token) {
                this.infoUser()
                this.isUserReady.next(true)
            }
        })
    }

    public isReady(): Promise<void> {
        return new Promise((resolve) => {
            this.isUserReady.subscribe((isReady) => {
                if (isReady) {
                    resolve()
                }
            })
        })
    }

    public getUser() {
        return this.user
    }

    getUserInfo(): Observable<SelfUser> {
        return this.userSubscription.asObservable()
    }

    setUserInfo(user: SelfUser) {
        this.userSubscription.next(user)
    }

    public async infoUser() {
        await this.isReady()
        return firstValueFrom(
            this.http
                .get<SelfUser>(this.env.API_URL + 'user_info', {
                    headers: {
                        'Access-Control-Allow-Origin':
                            this.env.API_URL + 'user_info',
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: this.token.value,
                    },
                })
                .pipe(
                    timeout(1000),
                    map((resp) => {
                        this.user = resp
                        this.setUserInfo(resp)
                        return this.isUserReady.next(true)
                    }),
                ),
        )
    }

    public updateUser(user: SelfUser) {
        return firstValueFrom(
            this.http.post(this.env.API_URL + 'user_info', user, {
                headers: {
                    'Access-Control-Allow-Origin':
                        this.env.API_URL + 'user_info',
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Token ' + this.token.value,
                },
            }),
        )
    }

    public resetData() {
        this.user = {} as SelfUser

        this.isUserReady.next(false)
    }
}
