/* eslint-disable @typescript-eslint/quotes */
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Storage } from '@ionic/storage-angular'
import { HttpClientModule } from '@angular/common/http'
import { AuthService } from './auth/auth.service'
import { EnvService } from './env/env.service'
import { AlertService } from './alert/alert.service'
import { UserService } from './user/user.service'
import { LocalStorageService } from './storage/local-storage.service'
import { DatabaseService } from './database/database.service'

@NgModule({
    declarations: [],
    imports: [CommonModule, HttpClientModule],
    providers: [
        AuthService,
        AlertService,
        EnvService,
        UserService,
        LocalStorageService,
        Storage,
        DatabaseService,
    ],
})
export class ServicesModule {}
