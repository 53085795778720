<ion-app>
    <ion-split-pane
        contentId="main-content"
        [when]="isPC && !splitPaneDisabled">
        <ion-menu contentId="main-content" type="push" #menuElement>
            <ion-toolbar class="mobile-800-only">
                <ion-buttons slot="end" (click)="closeMenu()">
                    <ion-icon
                        class="menu-icon"
                        color="danger"
                        name="close-circle-outline"
                        slot="start"></ion-icon>
                    <ion-label
                        style="
                            color: var(--ion-color-danger);
                            font-weight: bold;
                            padding-right: 20px;
                        "
                        >Cerrar</ion-label
                    >
                </ion-buttons>
            </ion-toolbar>

            <div class="menu-header">
                <div class="silvanus-logo-container">
                    <img class="silvanus-logo" src="assets/logo.png" />
                </div>
                <h4 class="silvanus-title">SILVANUS</h4>
            </div>

            <div class="menu-items-container">
                <div
                    *ngFor="let p of appPages; let i = index"
                    class="menu-item-wrapper">
                    <div
                        class="menu-item"
                        [class.expanded]="isExpandedItem(i) && p.subPages"
                        [class.selected]="isExpandedItem(i) && !p.subPages"
                        (click)="toggleExpand(i)"
                        style="cursor: pointer">
                        <ion-icon [name]="p.icon" slot="start"></ion-icon>
                        <ion-label>{{ p.title }}</ion-label>
                        <ion-icon
                            *ngIf="p.subPages"
                            class="menu-item-chevron"
                            [class.expanded]="isExpandedItem(i)"
                            slot="end"
                            name="chevron-forward-circle-outline"></ion-icon>
                    </div>
                    <div
                        class="sub-menu-box"
                        *ngIf="p.subPages"
                        [class.hidden]="!isExpandedItem(i)"
                        [@expandCollapse]="
                            isExpandedItem(i) ? 'expanded' : 'collapsed'
                        "
                        (click)="closeMenu()">
                        <div
                            class="sub-menu-item"
                            *ngFor="let sub of p.subPages"
                            [routerLink]="sub.url"
                            routerLinkActive="active">
                            <ion-icon [name]="sub.icon"></ion-icon>
                            <ion-label>{{ sub.title }}</ion-label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="avatar-container" routerLink="/profile">
                <!--                 <div class="avatar-initials-container">
                    <div class="avatar-initials">
                        {{ user.name ? user.name[0].toUpperCase() : 'U' }}
                    </div>
                </div> -->
                <ion-avatar>
                    <img src="assets/diazramos.png" />
                </ion-avatar>
                <div class="avatar-info">
                    <ion-label class="avatar-name">{{
                        user.name || 'Usuario'
                    }}</ion-label>
                    <ion-label class="avatar-email">
                        {{ user.email || 'email@usuario.com' }}
                    </ion-label>
                </div>
            </div>
            <div class="menu-separator"></div>

            <div class="profile-and-logout">
                <div class="profile-icon" routerLink="/profile">
                    <ion-icon name="person-outline"></ion-icon>
                </div>
                <div class="logout-icon" (click)="logout()">
                    <ion-icon name="log-out-outline"></ion-icon>
                </div>
            </div>
        </ion-menu>
        <ion-router-outlet id="main-content" main></ion-router-outlet>
    </ion-split-pane>
    <jeep-sqlite *ngIf="!native"></jeep-sqlite>
</ion-app>
