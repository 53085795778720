import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, tap, timeout } from 'rxjs/operators'
import { LocalStorageService } from '../storage/local-storage.service'
import { EnvService } from '../env/env.service'
import { NavController } from '@ionic/angular'
import { DataService } from '../data/data.service'
import { UserService } from '../user/user.service'
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs'
import { TokenService } from '../token/token.service'
import { Usuario } from 'src/app/models/models'
import { DatabaseService } from '../database/database.service'

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public isLoggedIn = new BehaviorSubject<boolean>(false)
    token: any
    refreshToken: any
    public role = new BehaviorSubject<string>('')

    constructor(
        private data: DataService,
        private user: UserService,
        private http: HttpClient,
        private storage: LocalStorageService,
        private env: EnvService,
        private navCtrl: NavController,
        private tokenService: TokenService,
        private dbService: DatabaseService,
    ) {
        this.getToken()
    }

    async getToken(): Promise<any> {
        // Get refresh token from storage
        this.refreshToken = await this.storage.getItem('refresh_token')
        // If refresh token is not null, get new token
        //////////console.log("refreshToken: ", this.refreshToken);
        if (this.refreshToken != null) {
            try {
                await this.doRefreshToken()
                this.isLoggedIn.next(true)
                return this.token
            } catch (error) {
                //////////console.log("Error in getToken: ", error);
                this.isLoggedIn.next(false)
                return null
            }
        }
    }

    getRole(): Observable<string> {
        return this.role.asObservable()
    }

    getRoleValue(): string {
        return this.role.getValue()
    }

    setRole(role: string): void {
        this.role.next(role)
    }

    login(name: string, password: string): Observable<any> {
        return this.http
            .post(
                `${this.env.API_URL}login`,
                {
                    email: name,
                    password: password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            )
            .pipe(
                timeout(2000),
                tap((data: any) => {
                    this.token = data.token
                    this.refreshToken = data.refresh_token
                    this.storage.setItem('refresh_token', this.refreshToken)
                    this.isLoggedIn.next(true)
                    this.tokenService.setToken(this.token)
                }),
            )
    }

    register(nombre: string, email: string, password: string): Observable<any> {
        return this.http
            .post(
                `${this.env.API_URL}registration`,
                {
                    user: {
                        name: nombre,
                        email: email,
                        password: password,
                    },
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': `${this.env.API_URL}session`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            )
            .pipe(
                timeout(1000),
                catchError((error) => {
                    //////////console.log("Error in register: ", error);
                    throw error
                }),
            )
    }

    async logout(): Promise<void> {
        try {
            this.token = null
            this.isLoggedIn.next(false)
            this.storage.remove('refresh_token')
            this.navCtrl.navigateRoot('/landing')
            this.data.resetData()
            this.user.resetData()
            this.role.next('')
            this.dbService.dropDatabase()
        } catch (error) {
            //////////console.log("Error in logout: ", error);
            throw error
        }
    }

    async isLogged(): Promise<boolean> {
        if (this.isLoggedIn.getValue()) {
            return this.isLoggedIn.getValue()
        } else {
            try {
                await this.getToken()
                return this.isLoggedIn.getValue()
            } catch (error) {
                //////////console.log("Error in isLogged: ", error);
                throw error
            }
        }
    }

    // REFRESH TOKEN //
    async doRefreshToken(): Promise<void> {
        try {
            const resp = (await firstValueFrom(
                this.http.post(
                    `${this.env.API_URL}tokens/refresh`,
                    {},
                    {
                        headers: {
                            'Access-Control-Allow-Origin': `${this.env.API_URL}tokens/refresh`,
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            Authorization: `Bearer ${this.refreshToken}`,
                        },
                    },
                ),
            )) as any
            this.token = resp['token']
            this.setRole(resp.role)
            this.user.infoUser()
            this.refreshToken = resp.refresh_token
            this.storage.setItem('refresh_token', this.refreshToken)
            this.tokenService.setToken(this.token)
            this.isLoggedIn.next(true)
        } catch (error) {
            //////////console.log("Error in doRefreshToken: ", error);
            throw error
        }
    }

    acceptInvitation(
        password: string,
        password_confirmation: string,
        invitation_token: string,
    ): Promise<any> {
        return firstValueFrom(
            this.http.put(
                this.env.API_URL + 'invitation',
                {
                    user: {
                        password: password,
                        password_confirmation: password_confirmation,
                        invitation_token: invitation_token,
                    },
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin':
                            this.env.API_URL + 'invitation',
                    },
                },
            ),
        ).catch((error) => {
            console.error('Error in acceptInvitation: ', error)
            throw error
        })
    }

    // RESET PASSWORD //
    async resetPassword(email: string): Promise<any> {
        try {
            await this.http
                .post(
                    `${this.env.API_URL}password`,
                    {
                        user: {
                            email: email,
                        },
                    },
                    {
                        responseType: 'text',
                        headers: {
                            'Access-Control-Allow-Origin': `${this.env.API_URL}password`,
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .toPromise()
            //////////console.log("Password reset email sent");
        } catch (error) {
            //////////console.log("Error in resetPassword: ", error);
            throw error
        }
    }

    async retrievePassword(
        password: string,
        password_confirmation: string,
        reset_password_token: string,
    ): Promise<string | undefined> {
        try {
            //////////console.log("password: ", password);
            //////////console.log("password_confirmation: ", password_confirmation);
            //////////console.log("reset_password_token: ", reset_password_token);
            const resp = await firstValueFrom(
                this.http.put(
                    `${this.env.API_URL}password`,
                    {
                        user: {
                            password: password,
                            password_confirmation: password_confirmation,
                            reset_password_token: reset_password_token,
                        },
                    },
                    {
                        responseType: 'text',
                        headers: {
                            'Access-Control-Allow-Origin': `${this.env.API_URL}password`,
                        },
                    },
                ),
            )
            //////////console.log("Password reset successful");
            return resp
        } catch (error) {
            //////////console.log("Error in retrievePassword: ", error);
            throw error
        }
    }

    // DELETE ACCOUNT //
    async deleteAccount(): Promise<any> {
        try {
            await this.http
                .get(`${this.env.API_URL}user/delete_account`, {
                    headers: {
                        'Access-Control-Allow-Origin': `${this.env.API_URL}user/delete_account`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                })
                .toPromise()
            //////////console.log("Account deleted");
        } catch (error) {
            //////////console.log("Error in deleteAccount: ", error);
            throw error
        }
    }
}
